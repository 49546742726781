
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from "vuex";
import PaymentService from '@/services/PaymentService';
import ShipService from "@/services/ShipService";
import SalesService from "@/services/SalesService";
import store from "@/store";
import Utils from '@/utility/utils';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Checkbox from "primevue/checkbox"
import SOQuotesService from '@/services/SOQuotesService';

const soquoteService = new SOQuotesService(process.env.VUE_APP_ABSTRACTION_API);
const salesService = new SalesService(process.env.VUE_APP_ABSTRACTION_API);
const paymentService = new PaymentService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  components: {
    Button,
    Dialog,
    InputText,
    Checkbox,
    InputNumber,
  },
  data() {
    return {
      visibleEditPart: false,
      editPart: {} as any,
      oa: false,
      partsButtonItems: [
      // {
      //   label: 'Continue To Payment',
      //   icon: 'pi pi-angle-double-right',
      //   class: 'continue-to-payment-btn',
      //   command: () => {
      //     this.$router.push('/sales/payment');
      //   }
      // },
      {
        label: 'Request Quote',
        class: 'continue-to-payment-btn',
        command: () => {
          this.submitQuote();
        }
      },
    ],
    showTenderAmountModal: false,
    updatingTndr: {} as any,
    tndrIndex: 0,
    newTndrAmt: "",

    }
  },
  computed: {
    ...mapState(["pos"]),
    ...mapGetters({
        getTaxLoading: "pos/getTaxLoading",
        getOrder: "pos/getCurrentOrder",
        getCustomer: "customer/getCust",
        getTenders: "pos/getTenders",
        shippingAddress: "pos/getShippingAddress",
        getTaxAmount: "pos/getTaxAmount",
        getPo: "pos/getPoNumber",
        getNotes: "pos/getNotes",
        getSelectedCoCode:"control/getSelectedCoCode",
      }),
    empty(): boolean {
      return parseFloat(this.total) === 0
    },
    total() {
      let sum = 0;
      this.getOrder.forEach((part: any) => {
        const quantity = part.quantity > 1 ? part.quantity : 1; 
        sum += parseFloat(part.price) * quantity || 0 ;
        sum += parseFloat(part.order_amount) || 0;
        sum += parseFloat(part.balance) || 0;
      }); 
      return sum.toFixed(2);
    },
    totalWithTax(): string {
      let sum = parseFloat(this.total) + (parseFloat(this.getTaxAmount) || 0)
      return sum.toFixed(2);
    },
    remainingBalance(): any {
      let tenderAmount = 0
      this.getTenders.forEach((tender:any) => {
        tenderAmount += parseFloat(tender.check_amount)
      });
      return (Math.round((parseFloat(this.total) - tenderAmount) * 100) / 100).toFixed(2)
    },
    remaining(): boolean {
      return this.remainingBalance > 0 && this.$route.fullPath === '/sales/payment';
    },
  },
  methods: {
    ...mapActions({
      removeFromOrder: "pos/removeFromOrder",
      addOaLine:"pos/addOaLine",
      addChange:"pos/addChange",
      setTranId: "pos/setTransactionId",
      addNotification: "notification/add",
      clearTenders:"pos/clearTenders",
      clearOrder: "pos/clearOrder",
      removeTndr: "pos/removeTender",
      updateTenderAmt: "pos/updateTenderAmount",
      setLastOrderId: "pos/setLastOrderId",
      setPoNum: "pos/setPoNumber",
    }),
    removeTender(event: any, index: number) {
      this.removeTndr(index)
    },
    calculateStep(part: any) {
      return Utils.calculateQtyStep(part);
    }, 
    submitQuote() {
      let line_items = [] as any
      this.getOrder.forEach((item: any) => {
        line_items.push({
          lis: (line_items.length + 1).toString(),
          li_parts: item.part_number,
          li_order_qtys: (item.quantity).toString(),
          li_prices: item.price,
          li_descs: item.desc,
          li_sched_dates_items: [
            {
              li_sched_dates: Utils.formatDate(new Date()),
              li_sched_qtys: (item.quantity).toString(),
            },
          ],
        })
      });
      let ship_address_items = [] as any
      this.shippingAddress.ship_to_items.forEach((addressLine:any) => {
        ship_address_items.push ({
          address : addressLine.ship_to
        })
      });
      soquoteService.postSOQuote({
            date: Utils.formatDate(Date()),
            customer: this.getCustomer.cust_id,
            name: this.getCustomer.name,
            cust_code: this.getCustomer.code,
            address_items: ship_address_items,
            lis_items: line_items,
          })
          .then((response) => {

            let saleId = (response as any).data.recordId;
            const notification = {
              message: `Successfully Created Quote #${saleId}.`,
              type: "success",
            };
            store.dispatch("notification/add", notification);
            this.clearTenders();
            this.clearOrder();
            this.$router.push('/sales/parts')
          })
          .catch((error) => {
            const notification = {
              message: error?.response?.data.error || "Error processing quote",
              type: "error",
            };
            store.dispatch("notification/add", notification);
          });
    },
    submit(cfee?: string) {
      if(this.$route.fullPath ==='/sales/payment') {
        if (this.remainingBalance < 0) {
          if (this.oa) {
            this.addOaLine(this.remainingBalance.toString())
          } else {
            this.addChange(this.remainingBalance)
          }
        }
        paymentService.Pay(this.getTenders).then((response: any)=>
        {
          if(response.details) {
             this.setTranId(response.details[0].recordId)
          }
          else {
            this.setTranId(response.recordId)
          }
          if(!(response.error === null || response.error === '')) {
            this.addNotification({
              message: `Failed to Process Payment. ${response.error}`,
              type: "error",
            });
          } else {
            if(response.status === 'success') {
              this.$router.push('/sales/confirmation')
            }
          }
        })
      }
      else if(this.$route.fullPath === '/sales/shipping') {
        this.createOrder()
      } else {
        // default route if clicked the button while on the parts list 
        this.$router.push('/sales/payment');
      }
    },
    createOrder() {
      if(this.getCustomer.po_required === 'Y' && this.getPo === null) {
        this.$emit('orderSubmitted', true)
        return
      }
      let line_items = [] as any
      this.getOrder.forEach((item: any) => {
        line_items.push({
          lis: (line_items.length + 1).toString(),
          li_parts: item.part_number,
          li_order_qtys: (item.quantity).toString(),
          li_prices: item.price,
          li_descs: item.desc,
          li_sched_dates_items: [
            {
              li_sched_dates: Utils.formatDate(new Date()),
              li_sched_qtys: (item.quantity).toString(),
            },
          ],
        })
      });
      let ship_address_items = [] as any
      this.shippingAddress.ship_to_items.forEach((addressLine:any) => {
        ship_address_items.push ({
          ship_address : addressLine.ship_to
        })
      });
      salesService.postOrder({
            so_id: '',
            date: Utils.formatDate(Date()),
            sold_to: this.getCustomer.cust_id,
            book_date: Utils.formatDate(Date()),
            status: "N",
            cust_code: this.getCustomer.code,
            ship_address_items: ship_address_items,
            ship_attn: this.shippingAddress.ship_attn,
            ship_city: this.shippingAddress.ship_city,
            ship_comm: this.shippingAddress.ship_comm,
            ship_country: this.shippingAddress.ship_country,
            ship_name: this.shippingAddress.ship_name,
            ship_seq: this.shippingAddress.ship_seq,
            ship_state: this.shippingAddress.ship_state,
            // ship_via: this.shipVia || this.shippingAddress.ship_via,
            ship_zip: this.shippingAddress.ship_zip,
            lis_items: line_items,
            order_amount: this.total,
            po_number: this.getPo,
            notes: this.getNotes,
            co_code: this.getSelectedCoCode,
            // type: this.POType,
            // signature: this.signature,
            // received_by: this.receivedBy,
            // received_date: this.receivedDate,
            // custom_fields: {
            //   ...values
            // }
          })
          .then((response) => {

            let saleId = (response as any).data.recordId;
            this.clearTenders();
            this.clearOrder();
            this.setPoNum(null)
            // this.clearCust();
            // this.clearMenuItems();
            this.setLastOrderId(saleId)
            // update parent to load new order
            this.$emit('orderCreated', saleId);
            this.$emit('orderSubmitted', false)
            this.$router.push('/sales/confirmation')
            
          })
          .catch((error) => {
            const notification = {
              message: error?.response?.data.error || "Error processing order",
              type: "error",
            };
            store.dispatch("notification/add", notification);
          });
    },
    goToShipping() {
      this.$router.push('/sales/shipping')
    },
    goToEditQuote() {
      this.$router.push('/sales/edit-quote')
    },
    removePart(event: any, index: any) {
      event.stopPropagation()
      this.removeFromOrder(index)
    },
    formatPrice(amount: number | string) {
      return Utils.formatPrice(amount);
    },
    updateTender(tender: any, index: number) {
      this.showTenderAmountModal = true
      this.updatingTndr = tender
      this.tndrIndex = index
    },
    updateTenderAmount() {
      let newTender = JSON.parse(JSON.stringify(this.getTenders[this.tndrIndex]))
      newTender.check_amount = (Math.round(parseFloat(this.newTndrAmt) * 100) / 100).toFixed(2)
      this.updateTenderAmt({tenderIndex: this.tndrIndex, tender: newTender})
      this.showTenderAmountModal = false
    },
    showEditPart(part: any, index: number) {
      if (this.$route.fullPath ==='/sales/parts') {
        this.visibleEditPart = true;
        this.editPart = {...part};
        this.editPart['index'] = index;
      }
    },
    removeEditPart(event: any, index: any) {
      this.visibleEditPart = false,
      this.removePart(event, index);
    },
    updatePart(part: any) {
      const partToUpdate = this.pos.currentOrder[part.index];
      if(partToUpdate) {
        partToUpdate.quantity = part.quantity;
        partToUpdate.price = part.price;
      }
      this.visibleEditPart = false;
    },
  },
})
